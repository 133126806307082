import React, { useState, useEffect } from 'react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from '../config/MsalConfig';
import { Container, Navbar } from 'react-bootstrap';
import MainMenu from './MainMenu';
import { logToDatadog } from '../index';

const Header = ({ t }) => {
    const [accessTokenAcquired, setAccessTokenAcquired] = useState(false);
    const [userData, setUserData] = useState({});
    const [profilePhoto, setProfilePhoto] = useState(undefined);
    const [overlayVisible, setOverlayVisible] = useState(false);
    const MAIN_WEBSITE_URL = process.env.REACT_APP_MAIN_WEBSITE_URL || "https://employee.oms-pruefservice.de";
    const IS_RETAIL = MAIN_WEBSITE_URL.includes('retail');
    const userCompany = userData?.mail?.split("@")[1]?.split(".")[0];
    
    useEffect(() => {
        window.screen.width < 1200 && setTimeout(() => {
            document.getElementById("greeting").classList.add("invisible");
            document.getElementById("icon-menu").classList.remove("invisible");
        }, 5000);
    }, []);

    const fetchUserDetails = async (accessToken) => {
        const headers = { Authorization: `Bearer ${accessToken}` };
        try {
            const [userDataResponse, userPhotoResponse] = await Promise.all([
                fetch('https://graph.microsoft.com/v1.0/me', { headers }),
                fetch('https://graph.microsoft.com/v1.0/me/photo/$value', { headers })
            ]);
            const userData = await userDataResponse.json();
            localStorage.setItem('userData', JSON.stringify(userData));
            setUserData(userData);
            if (userPhotoResponse.ok){
                const userProfilePhotoBlob  = await userPhotoResponse.blob();
                blobToBase64(userProfilePhotoBlob).then(base64String => {
                    localStorage.setItem('profilePhotoBase64String', base64String);
                });
                setProfilePhoto(URL.createObjectURL(userProfilePhotoBlob));
            } else {
                const defaultAvatar = process.env.PUBLIC_URL + "/images/default-avatar.png";
                localStorage.setItem('profilePhotoURL', defaultAvatar);
                setProfilePhoto(defaultAvatar);
            }
            logToDatadog("fetchUserDetails: User fetched data from Microsoft");
        } catch (fetchUserDetailsError) {
            logToDatadog(`fetchUserDetails: Error fetching user details: ${fetchUserDetailsError.message}`, "error");
        }
    };

    function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
            // reader.result contains the Base64 string
            const base64String = reader.result;
            resolve(base64String);
            };
            reader.readAsDataURL(blob);
        });
        }

    useEffect(() => {
        const fetchAccessTokenMSGraph = async () => {

            const activeAccount = msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
            if (!activeAccount) {
                return;
            }
            if (isMobileDevice()) {
                logToDatadog("fetchAccessTokenMSGraph: User is fetching token from Mobile device");
            } else {
                logToDatadog("fetchAccessTokenMSGraph: User is fetching token from Desktop device");
            }
            const tokenRequest = {
                scopes: ['openid', 'profile', 'user.read'],
                redirectUri: window.location.origin,
                account: activeAccount 
            };
            try {
                const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
                await fetchUserDetails(tokenResponse.accessToken);
                setAccessTokenAcquired(true);
            } catch (acquireTokenSilentError) {
                // Needs to provide credentials again
                if (acquireTokenSilentError instanceof InteractionRequiredAuthError || acquireTokenSilentError.message.includes('post_request_failed')) {
                    try {
                        await msalInstance.handleRedirectPromise().then((tokenResponse) => {
                            if (tokenResponse !== null) {
                                fetchUserDetails(tokenResponse.accessToken);
                                setAccessTokenAcquired(true);
                            } else {
                                msalInstance.acquireTokenRedirect(tokenRequest);
                            }
                        });
                    } catch (acquireTokenRedirectError) {
                        if (acquireTokenRedirectError.message.includes('redirect_in_iframe')) {
                            try {
                                await msalInstance.acquireTokenPopup(tokenRequest);
                            } catch (acquireTokenPopupError) {
                                if (acquireTokenPopupError.message.includes('popup_window_error')) {
                                    logToDatadog(`fetchAccessTokenMSGraph: popup_window_error: ${acquireTokenPopupError.message}`, "error");
                                } else {
                                    logToDatadog(`fetchAccessTokenMSGraph: Error acquiring access token method TokenPopup: ${acquireTokenPopupError.message}`, "error");
                                }
                            }
                        } else  {
                            logToDatadog(`fetchAccessTokenMSGraph: Error acquiring access token method TokenRedirect: ${acquireTokenRedirectError.message}`, "error");
                        }
                    }
                } else {
                    logToDatadog(`fetchAccessTokenMSGraph: Error acquiring access token method TokenSilent: ${acquireTokenSilentError.message}`, "error");
                }
            }
        };
        if (!accessTokenAcquired) {
            // Get first from local storage, if not exists: Call MSgraph
            const storedUserData = localStorage.getItem('userData');
            const storedProfilePhotoUrl = localStorage.getItem('profilePhotoURL');
            const profilePhotoBase64String = localStorage.getItem('profilePhotoBase64String');
            if (storedUserData && (storedProfilePhotoUrl || profilePhotoBase64String )){
                setUserData(JSON.parse(storedUserData));
                setProfilePhoto(storedProfilePhotoUrl || profilePhotoBase64String);
            } else {
                fetchAccessTokenMSGraph();
            }
        }
    }, [accessTokenAcquired]);

    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    const toggleOverlay = (display) => {
        setOverlayVisible(display);
        display === false ? document.body.style.overflow = "scroll" : document.body.style.overflow = "hidden";
    }

    return (
        <div className="header w-100">
            <Container className="p-0 w-100">
                <Navbar className="navbar-top d-flex justify-content-between align-items-center">
                    <Navbar.Brand className="m-0 p-0" role="button">
                        {!IS_RETAIL ?
                            <img alt="Company Logo" src={userCompany && process.env.PUBLIC_URL + "/images/labora-app_logos_" + userCompany + ".png"}
                                onError={({ currentTarget }) => currentTarget.src = process.env.PUBLIC_URL + "/images/labora-app_logos_labora-holding.png"}
                                height={70} width={70} onClick={() => { window.location.reload() }} />
                        :
                            <img alt="Company Logo" src={process.env.PUBLIC_URL + "/images/oms_retail_logo_red_blue_rgb.png"}
                            height={40}  onClick={() => { window.location.reload() }} />
                        }
                    </Navbar.Brand>
                    <div id="hamburger-menu" className="d-flex flex-column justify-content-center align-items-center text-center position-relative">
                        {Object.keys(userData).length > 0 && <div id="greeting" className="greeting d-flex flex-column justify-content-center align-items-center text-center position-absolute">
                            <b className="greeting__hello">Hello</b>
                            <h5 className="greeting__given-name">{userData?.givenName || userData?.displayName}</h5>
                        </div>}
                        <img id="icon-menu" className="invisible" src={process.env.PUBLIC_URL + "/images/icon-menu.svg"} alt="" height={24} width={24} onClick={() => window.screen.width < 1200 && toggleOverlay(!overlayVisible)} />
                    </div>
                    <img alt="Profile" className="header__avatar rounded-circle" height={48} width={48} src={profilePhoto} onClick={() => window.screen.width < 1200 && toggleOverlay(!overlayVisible)} />
                </Navbar>
                <div id="view-main-menu" className="d-xl-none" style={overlayVisible ? {} : { display: "none" }}>
                    <MainMenu toggleOverlay={toggleOverlay} />
                    <div className="footer-button-mobile d-xl-none">
                        <button className="btn-secondary" onClick={() => toggleOverlay(false)}>
                            <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" />
                            {t("Button.back")}
                        </button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Header
