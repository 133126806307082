import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './components/App';

// Datadog handler
datadogLogs.init({
  version: '0.1',
  clientToken: `${process.env.REACT_APP_DATADOG_TOKEN}`,
  site: 'datadoghq.eu',
  service: `${process.env.REACT_APP_DATADOG_SERVICE_NAME}`,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100
});
export function logToDatadog(Message, StatusType = 'info') {
  // Possible StatusType: "error", "info", "debug", "warn"
  try {
    if (datadogLogs){
      datadogLogs.logger.log(Message, { /* Additional context */ }, StatusType);
    }
  } catch (error) {
    console.error("Failed to log message to DataDog!:", error);
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>
);